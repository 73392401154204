import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import Container from '@/components/Layout/Container'
import ContainerSidebar from '@/components/Layout/ContainerSidebar'
import Wysiwyg from '@/components/Wysiwyg'
import TextAndImage from '@/components/TextAndImage'
import { getImage } from 'gatsby-plugin-image'
import Accordion from '@/components/Accordion'
import DownloadLink from '@/components/DownloadLink'
import FileRepeater from '@/components/FileRepeater'
import Seo from '@/components/Seo'
import LoadMore from '@/components/LoadMore'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { seotype } from '@/types.d'
interface TableOfContents {
  content: string
  heading: string
}
interface DefaultBookData {
  data: {
    form: any
    book: {
      id: string
      title: string
      gwBooksYear: string
      gwBooksLongSummary: string
      gwBooksShortSummary: string
      gwBooksContent: string
      coverImage: any
      gwBooksTableOfContents: Array<TableOfContents>
      translations: []
      file: string
      featuredImage: any
      uri: string
      seo: seotype
      language: {
        slug: string
      }
    }
    mainMenu: any
  }
}

const SingleBookTemplate = ({ data }: DefaultBookData) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(book.language.slug)
  }, [])

  const { book } = data
  const { form } = data
  const { allWpGwBook }: any = data
  const { wp }: any = data

  const bookImage = getImage(book?.coverImage?.node?.localFile)
  const langData = { languages: wp.languages, currentLang: book.language.slug, translations: book.translations }
  const { mainMenu } = data
  const file = book?.file ? JSON.parse(book?.file) : ''

  const files =
    langData.currentLang == 'fi'
      ? { subTitle: wp.files.subTitleFi, heading: wp.files.headingFi, files: wp.files.filesFi }
      : langData.currentLang == 'en'
      ? { subTitle: wp.files.subTitleEn, heading: wp.files.headingEn, files: wp.files.filesEn }
      : langData.currentLang == 'sv'
      ? { subTitle: wp.files.subTitleSv, heading: wp.files.headingSv, files: wp.files.filesSv }
      : { subTitle: wp.files.subTitleFi, heading: wp.files.headingFi, files: wp.files.filesFi }

  return (
    <Layout featuredImage={book.featuredImage} lang={langData} nav={mainMenu} form={form}>
      {book.seo && (
        <Seo
          title={book?.seo.title}
          description={book?.seo.metaDesc}
          keywords={book?.seo.metaKeyword}
          pathname={book?.uri}
          lang={book?.language?.slug}
          image={book?.featuredImage?.node?.localFile?.url}
          twitterTitle={book?.seo?.twitterTitle}
          twitterDescription={book?.seo?.twitterDescription}
          opengraphTitle={book?.seo?.opengraphTitle}
          opengraphDescription={book?.seo?.opengraphDescription}
          opengraphImage={book?.seo?.opengraphImage?.localFile?.url}
          twitterImage={book?.seo?.twitterImage?.localFile?.url}
        />
      )}
      <Container>
        <TextAndImage
          useh1={true}
          isIndent={true}
          heading={book.title}
          contentUpper={book.gwBooksShortSummary}
          img={bookImage}
          imgBg={'bg-green'}
          className=""
        >
          <p className="font-bold">{book.gwBooksLongSummary}</p>
          {file?.url && file?.anchor && (
            <DownloadLink className="mt-8" to={file?.url}>
              {file?.anchor}
            </DownloadLink>
          )}
        </TextAndImage>
      </Container>
      {book.gwBooksContent && (
        <Container>
          <Wysiwyg className="max-w-screen-md w-full my-10">{book.gwBooksContent}</Wysiwyg>
        </Container>
      )}
      <ContainerSidebar
        sidebar={<FileRepeater files={files.files} heading={files.heading} subHeading={files.subTitle} />}
        className="mt-10 mb-20"
        hideSideOnMobile={false}
      >
        {book.gwBooksTableOfContents &&
          book.gwBooksTableOfContents.map((item: any, index: number) => {
            return (
              <Accordion
                key={item.heading + index}
                className="border-green"
                buttonClass="hover:bg-green hover:text-white"
                activeBg="bg-green text-white"
                heading={item.heading}
                richContent={true}
              >
                {item.content}
              </Accordion>
            )
          })}
      </ContainerSidebar>
      {allWpGwBook.nodes && (
        <Container className="bg-lightlatte py-10 -mb-14">
          <h2 className="mb-10 text-3xl">
            <Trans>Kalevalaseuran vuosikirjat vuodesta 1980</Trans>
          </h2>
          <LoadMore
            buttonText={t('Näytä lisää')}
            classNameButton="flex items-center justify-center mt-10 mb-6"
            postType="books"
            total={allWpGwBook.totalCount}
            className="grid sm:grid-cols-2 gap-10 sm:gap-6 md:grid-cols-4 md:gap-6 xl:gap-10"
            posts={allWpGwBook.nodes}
          />
        </Container>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query BookById($id: String!, $postlang: [String], $menu: [WpMenuLocationEnum], $translang: String) {
    book: wpGwBook(id: { eq: $id }) {
      id
      title
      uri
      gwBooksYear
      gwBooksLongSummary
      gwBooksShortSummary
      gwBooksContent
      seo {
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            url
          }
        }
        twitterImage {
          localFile {
            url
          }
        }
      }
      file: gwBooksFile
      gwBooksTableOfContents {
        content
        heading
      }
      ...featuredHeroImageBook
      ...langdataBook
      coverImage: featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, transformOptions: { cropFocus: CENTER })
            }
          }
        }
      }
    }
    allWpGwBook(sort: { fields: gwBooksYear, order: DESC }, filter: { language: { slug: { in: $postlang } } }) {
      ...allBooks
    }
    wp {
      ...bookFiles
      ...allLang
    }
    mainMenu: wpMenu(locations: { in: $menu }) {
      ...navigation
    }
    locales: allLocale(filter: { language: { eq: $translang } }) {
      ...stringTranslations
    }
    form: wpGravityFormsForm(formId: { eq: 1 }) {
      ...GravityFormFields
    }
  }
`

export default SingleBookTemplate
