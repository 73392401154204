import React from 'react'
import Download from '@/assets/Download'

interface DownlaodLinkData {
  children: string
  to: string
  className?: string
}

const DownloadLink = ({ children, to, className = '' }: DownlaodLinkData) => {
  return (
    <a
      href={to}
      className={`${className} download-link flex items-center bg-blue text-latte rounded-lg p-4 hover:bg-hovbrown hover:text-white focus:bg-hovbrown focus:text-white`}
      download
    >
      <span className="mr-2">
        <Download />
      </span>
      {children}
    </a>
  )
}
export default DownloadLink
