import React, { useState, useRef } from 'react'
import Arrow from '@/assets/Arrow'

interface AccordionData {
  heading: string
  children: any
  activeBg: string
  className?: string
  richContent?: boolean
  buttonClass?: string
}

const Accordion = ({
  heading,
  children,
  activeBg,
  className = '',
  richContent = false,
  buttonClass = '',
}: AccordionData) => {
  const [setActive, setActiveState] = useState('')
  const [setHeight, setHeightState] = useState('0px')
  const [setRotate, setRotateState] = useState('arrow')

  const content: any = useRef(null)

  const toggle = (): void => {
    setActiveState(setActive === '' ? `active ${activeBg}` : '')
    setHeightState(setActive === `active ${activeBg}` ? '0px' : `${content.current.scrollHeight}px`)
    setRotateState(setActive === `active ${activeBg}` ? 'arrow' : 'arrow rotate')
  }

  return (
    <>
      <div className={`${className} flex flex-col border my-2 rounded-xl overflow-hidden`}>
        <button className={`p-5 accordion accordion-ani flex ${setActive} ${buttonClass}`} onClick={toggle}>
          <h3 className="text-left tracking-wider text-xl">{heading}</h3>
          <span className={`${setRotate} accordion-ani arrow-icon`}>
            {' '}
            <Arrow />{' '}
          </span>
        </button>
        <div ref={content} style={{ maxHeight: `${setHeight}` }} className="overflow-auto accordion-ani px-8">
          <div className="my-7 wysiwyg">
            {richContent ? <div dangerouslySetInnerHTML={{ __html: children }} /> : <p>{children}</p>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion
